import React, { useState } from 'react';
import logo from '../sai-logo.png';
import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';

  function Nav() {  
    const [showNav, setShowNav] = useState(false);

    return (
      <MDBNavbar className='fixed-top' expand='lg' light bgColor='light'>
        <MDBContainer>
          <MDBNavbarBrand href='/'>
          <img
              src={logo}
              height='80'
              alt=''
              loading='lazy'
            />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNav}>
            <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
            <MDBNavbarItem>
                <MDBNavbarLink active aria-current='page' href='/' className="custom-nav">
                   Home
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link custom-nav' role='button'>
                  About Sansthan
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem link tag={Link} to={`/Temple-history`}>Temple History</MDBDropdownItem>
                  <MDBDropdownItem link >Sansthan Regulations</MDBDropdownItem>
                  <MDBDropdownItem link>Annual Events</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/Daily-programs' className="custom-nav">
                  Daily Programs
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/Poojas' className="custom-nav">Pooja's</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='/Upcoming-events' className="custom-nav">Upcoming Events</MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
            <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
            <MDBNavbarItem>
                <MDBNavbarLink aria-current='page' href='/Donations' className='ngs-dbtn'>
                    Donate Now
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    );
  }

  export default Nav;