import React from 'react';
import Carousl from '../Components/Carousel';
import { Link } from 'react-router-dom';
import { 
  MDBContainer, 
  MDBRow, 
  MDBCol, 
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBIcon
} from 'mdb-react-ui-kit';
import Footer from '../Components/Footer';
 import Icon1 from '../Components/assets/img/nagasai-harathi.png';
 import Icon2 from '../Components/assets/img/pooja.png';
 import Icon3 from '../Components/assets/img/events.png';
 import Icon4 from '../Components/assets/img/nagasai-icon.png'
 import Idol from '../Components/assets/img/nagasai-Idol.png';
 import Temple from '../Components/assets/img/nagasai-temple.jpg';
 import Icon5 from '../Components/assets/img/lotus-white.webp';
 import Gallery1 from '../Components/assets/img/gallery1.jpg';
 import Gallery2 from '../Components/assets/img/gallery2.jpg';
 import Gallery3 from '../Components/assets/img/gallery3.jpg'

export default function Home() {
  return (

    
    
    <div className='wrap-bg'>

<audio id="audio" loop autoplay> 
  <source src="%PUBLIC_URL%/music.
  mp3" type="audio/mpeg" /> 
</audio> 
      <Carousl />
      <MDBContainer className='pt-5'>
      <MDBRow>
        <MDBCol size='md pt5 pb-5'>
          <div className='ps-3 pe-3'>
            <h3><span className='clr-orrange'>Naga Sai Kshetra</span> is the Mahakhandam built by Sadhguru Sai Baba himself as the builder of the ancient Kshetra. Karta, Karma, Kriya All Sri Sainath maharaj for this holy work.</h3>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>

        <MDBCol size='md'>
          <MDBCard className='pt-5 pb-5 shadow-0'>
            <MDBCardImage
              src={Icon1}
              className='img-icon'
              alt='...' />
            <MDBCardBody>
              <MDBCardTitle>Abhishek Pooja</MDBCardTitle>
              <MDBCardText>
                Sai Baba Abhishek Pooja is a sacred ritual performed by devotees to offer worship and devotion to Sai Baba of Nagasai kshetra.
              </MDBCardText>
              <MDBBtn href='#' color='warning'>Book Now</MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol size='md'>
          <MDBCard className='pt-5 pb-5  bg-orrange'>
            <MDBCardImage
              src={Icon2}
              className='img-icon'
              alt='...' />
            <MDBCardBody>
              <MDBCardTitle className='text-white'>Temple Timings</MDBCardTitle>
              <MDBCardText className='text-white'>
                The Naga Sai Kshetra is open to public from <strong>5 a.m. to 9.30 p.m.</strong> on all days except Thursdays and festive occasions wherein, its closing time is extended till 10.30 p.m. A huge turnout of devotees is always a norm at this temple.
              </MDBCardText>

            </MDBCardBody>
          </MDBCard>

        </MDBCol>

        <MDBCol size='md'>
          <MDBCard className='pt-5 pb-5 shadow-0'>
            <MDBCardImage
              src={Icon3}
              className='img-icon'
              alt='...' />
            <MDBCardBody>
              <MDBCardTitle>Upcoming Events </MDBCardTitle>
              <MDBCardText>
                Important Festivals Celebrated By The Naga Sai Kshetra International Dharma Sansthan Trust, At Temple.
              </MDBCardText>

              <MDBBtn href='#' color='warning'>Events List</MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow className='pt-5 mt-5 mb-5'>
        <MDBCol size='md' className='pe-0'>
          <div className='abt-info bg-grdnt rounded-start p-5 text-white'>
            <img
              src={Icon5}
              alt=''
              loading='lazy' />
            <h3 className='pt-2 pb-3'>Naga Sai Kshetra, Injapur, Hyderabad.</h3>
            <p>In the realm of infinite philosophy, where love, kindness, and compassion converge, the avatar Saibaba emerges as a beacon of divine grace. Fifteen years ago, Sai Baba embarked on the remarkable endeavor of single-handedly reconstructing an ancient field, now known as Sri Naga Sai Kshetra, transforming it into a sacred ground that bears the indelible mark of his divine touch. This extraordinary endeavor transcends human capability, Shri Sai Baba himself led the construction, leaving behind a legacy of spiritual significance.</p>

            
          </div>
        </MDBCol>
        <MDBCol size='md' className='ps-0'>
          <img
            src={Temple}
            alt=''
            loading='lazy'
            className='img-fluid shadow-4 rounded-end' />
        </MDBCol>
      </MDBRow>

      <MDBRow className='pt-5 pb-5'>
        <MDBCol size='md pt-5'>
          <div className="d-grid gap-4 col-10 float-end">
            <MDBBtn rounded outline color='warning' href="/Temple-history">Temple History</MDBBtn>
            <MDBBtn rounded outline color='warning' className='me-3'>Sansthan Regulations</MDBBtn>
            <MDBBtn rounded outline color='warning' className='me-4' href="/Poojas">Pooja's</MDBBtn>
            <MDBBtn rounded outline color='warning' className='me-3' href="/Daily-programs" >Daily Programme</MDBBtn>
            <MDBBtn rounded outline color='warning'>Daily Programme</MDBBtn>
          </div>
        </MDBCol>
        <MDBCol size='md'>
          <img
            src={Idol}
            width='300'
            alt=''
            loading='lazy' />
            <a class="btn btn-outline-danger mt-1" href="#!" role="button">
            <MDBIcon fas icon="play" /> Live Streaming
            </a>
        </MDBCol>
        <MDBCol size='md pt-5'>
          <div className="d-grid gap-4 col-10 float-start">
            <MDBBtn rounded outline color='warning'>Sai Sainyam</MDBBtn>
            <MDBBtn rounded outline color='warning' className='ms-3' href="/Upcoming-events">Upcoming Events</MDBBtn>
            <MDBBtn rounded outline color='warning' className='ms-4'>How to Reach</MDBBtn>
            <MDBBtn rounded outline color='warning' className='ms-3'href="/Guru-pournami" >Gallery</MDBBtn>
            <MDBBtn rounded outline color='warning'>Sai Tho Sahavasam</MDBBtn>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    
    <MDBContainer fluid className=''>
        <MDBRow>
          <MDBCol size='md pt-5 pb-5'>
            <div className='video-sec rounded-4 pt-5 pb-5 p-6 ps-5 pl-5 text-white'>
              <img
                src={Icon4}
                height='140'
                alt=''
                loading='lazy' />
              <h1>Let's get united to support the temple's activities, maintenance, and charitable initiatives.</h1>
              <h4>Serve the God by sharing your contribution.</h4>
              <MDBBtn href='/Donations' color='warning' className='mt-4'>Contribute Now!</MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>


      <MDBContainer className='mb-5'>
        
          <h2>Events Gallery</h2>
          <MDBRow className='mt-5'>
          <MDBCol size='md mb-5 pb-5'>
          <div class="bg-image rounded">
            <img
              src={Gallery1}
              class="img-fluid rounded"
              alt="Sample"
            />
            <div class="mask">
              <div class="d-flex justify-content-center align-items-center h-100">
                <Link to='/Guru-pournami' className='glink'>Temple Gallery</Link>
              </div>
            </div>
          </div>
          </MDBCol>

          <MDBCol size='md mb-5 pb-5'>
          <div class="bg-image rounded">
            <img
              src={Gallery2}
              class="img-fluid rounded"
              alt="Sample"
            />
            <div class="mask">
              <div class="d-flex justify-content-center align-items-center h-100">
                <Link to='/Guru-pournami' className='glink'>Guru Pournami</Link>
              </div>
            </div>
          </div>
          </MDBCol>

          <MDBCol size='md mb-5 pb-5'>
          <div class="bg-image rounded">
            <img
              src={Gallery3}
              class="img-fluid rounded"
              alt="Sample"
            />
            <div class="mask">
              <div class="d-flex justify-content-center align-items-center h-100">
                <Link to='/Guru-pournami' className='glink'>Naga Panchami</Link>
              </div>
            </div>
          </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      
      <Footer />
      </div>
      



  );

  
}
