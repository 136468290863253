
import React from 'react'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol, 
    MDBTable, 
    MDBTableHead, 
    MDBTableBody
  } from 'mdb-react-ui-kit';
  import Footer from '../Components/Footer'

export default function DailyPrograms() {
  return (
    <><div className='mt-5 p-5 text-center hero-bg text-white'>
          <h2 className='pt-5 mb-3'>Daily Programs</h2>
          <h6 className='mb-2'>Home / Daily Programs</h6>
      </div>
      <MDBContainer className='pt-5 mb-5'>
              <MDBRow>
                  <MDBCol size='md pt5'>
                      <div className='ps-3 pe-3'>
                          <h5>At <span className='clr-orrange'>Naga Sai Kshetra</span> Darshan is available daily during the times listed below. On festivals and event days, a different schedule may be followed that will be listed on the Annual Events page. Devotees desiring individulaized worship services requiring extended rituals are requested to make an appointment prior to their visit. This ensures that resources are maximized and you experience a joyouos visit and a satisfactory event. </h5>
                      </div>
                  </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md='10' offsetMd='1'     size='md pt-5' className='text-start'>
                <MDBTable bordered>
                <MDBTableHead>
                    <tr className='clr-orrange'>
                    <th scope='col'>Activities</th>
                    <th scope='col'>Timings</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                <tr>
                <th colSpan={2} className='table-warning'>
                    Morning
                </th>
                </tr>
                <tr>
                    <td>Kakada Harati</td>
                    <td>04:30 am</td>
                </tr>
                <tr>
                    <td>Abhishekam, Alamkarana</td>
                    <td>05:00 am</td>
                </tr>
                <tr>
                    <td>Abhisheka Puja of Manasa Devi Sri Vallidevi Sameta Subrahmanyeshwara Swamy and other Goddesses</td>
                    <td>06:30 am - 08:00 am</td>
                </tr>
                <tr>
                    <td>Naivedyam, Nivedana</td>
                    <td>08:30 am</td>
                </tr>
                <tr>
                    <td>Sri Sai Harathi</td>
                    <td>12:00 am</td>
                </tr>
                <tr>
                <th colSpan={2} className='table-warning'>  
                    Evening 
                </th>
                </tr>
                <tr>
                    <td>Punar Darshanam</td>
                    <td>05:00 pm</td>
                </tr>
                <tr>
                    <td>Shejh Harathi</td>
                    <td>07:00 pm - 10:00 pm</td>
                </tr>
                <tr>
                <th colSpan={2} className=''>
                  * A daily ritual of performing "Homam" (a sacred fire ceremony) is conducted at the temple.
                </th>
                </tr>

                </MDBTableBody>
                </MDBTable>
                </MDBCol>
              </MDBRow>
        </MDBContainer>

        <Footer />

          </>
  )
}
