import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { images, CustomImage } from "./images";
import Footer from '../Components/Footer';
import { 
  MDBContainer, 
  MDBRow, 
  MDBCol, 
} from 'mdb-react-ui-kit';

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));

export default function App() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  return (
    <div>
        <div className='mt-5 p-5 text-center hero-bg text-white'>
          <h2 className='pt-5 mb-3'>Upcoming Events</h2>
          <h6 className='mb-2'>Home / Events</h6>
      </div>
      <MDBContainer className="mt-5 mb-5 pt-5 plr-0">
        <MDBRow>
        <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
        </MDBRow>
      </MDBContainer>
      
      <Footer />
    </div>
  );
}