
import { Image } from "react-grid-gallery";
import Gal1 from '../Components/assets/img/GP-3.JPG';
import Gal2 from '../Components/assets/img/GP-2.JPG';
import Gal3 from '../Components/assets/img/GP-1.JPG';
import Gal4 from '../Components/assets/img/GP-4.JPG';
import Gal5 from '../Components/assets/img/GP-5.JPG';
import Gal6 from '../Components/assets/img/GP-6.JPG';
import Gal7 from '../Components/assets/img/GP-7.JPG';
import Gal8 from '../Components/assets/img/GP-8.JPG';
import Gal9 from '../Components/assets/img/GP-9.JPG';
import Gal10 from '../Components/assets/img/GP-10.JPG';
import Gal11 from '../Components/assets/img/GP-11.JPG';
import Gal12 from '../Components/assets/img/GP-12.JPG';
import Gal14 from '../Components/assets/img/GP-14.JPG';
import Gal15 from '../Components/assets/img/GP-15.JPG';
import Gal16 from '../Components/assets/img/GP-16.JPG';
import Gal17 from '../Components/assets/img/GP-17.JPG';
import Gal18 from '../Components/assets/img/GP-18.JPG';
import Gal19 from '../Components/assets/img/GP-19.JPG';
import Gal20 from '../Components/assets/img/GP-20.JPG';

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: Gal1,
    original: Gal1,
    width: 1600,
    height: 1000 ,
  },
  {
    src: Gal2,
    original: Gal2,
    width: 1600,
    height: 1000 ,
  },
  {
    src: Gal3,
    original: Gal3,
    width: 1600,
    height: 1000 ,
  },
  {
    src: Gal4,
    original: Gal4,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal5,
    original: Gal5,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal6,
    original: Gal6,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal7,
    original: Gal7,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal8,
    original: Gal8,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal9,
    original: Gal9,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal10,
    original: Gal10,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal11,
    original: Gal11,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal12,
    original: Gal12,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal14,
    original: Gal14,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal15,
    original: Gal15,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal16,
    original: Gal16,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal17,
    original: Gal17,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal18,
    original: Gal18,
    width: 1600,
    height: 1000 ,
  },

  {
    src: Gal19,
    original: Gal19,
    width: 1600,
    height: 1000 ,
  },
  
];