import React from 'react';
import './App.css';
import './Responsive.css';
  import Nav from './Components/Navbar';
  import Home from './Pages/Home';
  import About from './Pages/TempleHistory';
  import DailyPrograms from './Pages/DailyPrograms';
  import UpcomingEvents from './Pages/UpcomingEvents';
  import Poojas from './Pages/Poojas';
  import Donations from './Pages/Donations';
  import GuruPournami from './Pages/GuruPournami';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";


function App() {
  return (
    <div className='App'>
      <BrowserRouter>
       <Nav /> 
       <Routes>
         <Route index element={<Home />} />
         <Route path='/Temple-history' element={<About />} />
         <Route path='/Daily-programs' element={<DailyPrograms />} />
         <Route path='/Poojas' element={<Poojas />} />
         <Route path='/Upcoming-events' element={<UpcomingEvents />} />
         <Route path='/Donations' element={<Donations />} />
         <Route path='/Guru-pournami' element={<GuruPournami />} />
       </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
