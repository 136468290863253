import React from 'react'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol,
    MDBTable,
    MDBTableHead, 
    MDBTableBody 
  } from 'mdb-react-ui-kit';
  import Dlogo from '../Components/assets/img/bank-logo.png';
  import Scanner from '../Components/assets/img/scanner.jpg';
  import Footer from '../Components/Footer'

export default function Donations() {
  return (
    <div>
        <div className='mt-5 p-5 text-center hero-bg text-white'>
          <h2 className='pt-5 mb-3'>Donations</h2>
          <h6 className='mb-2'>Home / Donations</h6>
      </div>

      <MDBContainer className='mt-5 mb-5'>
        <MDBRow>
            <MDBCol md='9' className='text-left'>
                <h5>Donation Option Through Cheque/Fund Transfer/UPI</h5>
                <p>Kindly Contribute to the temple voluntarly for promote spiritual growth, provide educational programs, support local charities, maintain our sacred space, and organize cultural events.</p>
                <p>With the divine grace of Sri Sainadhu, there is a future plan to construct a free hospital, Vedic Vidyalaya, Goshala, and Nitya Annadana Satram in the area in front of this temple.</p>
            </MDBCol>
        </MDBRow>
        <MDBRow className='bg-light shadow-1-strong rounded-2 p-4'>
            <MDBCol className='text-left'>
            <img src={Dlogo} className="rounded mb-3" style={{ width: 250, height: 100 }} alt="" />
            <p><b>Donate using UPI ID : nagasaiksetra@sbi</b></p>  
            <h6>Fund Transfer / NEFT / RTGS / IMPS / Quick Pay</h6>
            <MDBTable className='mt-3'>
      
                <MDBTableBody>
                    <tr>
                    <th scope='row'>A/c Name 	:</th>
                    <td>NAGA SAI KSHETRA INTERNATIONAL DHARMA SANSTHAN</td>
                    </tr>
                    <tr>
                    <th scope='row'>A/C No.	:</th>
                    <td>35869959425</td>
                    </tr>
                    <tr>
                    <th scope='row'>IFSE Code 	:</th>
                    <td>SBIN0004347</td>
                    </tr>
                    <tr>
                    <th scope='row'>Branch		:</th>
                    <td>Vanasthalipuram, Hyderabad.</td>
                    </tr>   
                </MDBTableBody>
                </MDBTable>

                <h6>Contact Information:</h6>
                <p>If you have any questions or need assistance, please feel free to contact us at</p>
                <p><i class="fa fa-envelope me-3"></i>info@nagasaikshethram.org</p><p><i class="fa fa-phone me-3"></i> +91 9550 634 449</p>
            </MDBCol>
            <MDBCol>
                <img src={Scanner} className="mt-4 rounded img-thumbnail"  alt="" />
            </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </div>
  );
}
