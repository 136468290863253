import React from 'react'
import { 
    MDBContainer, 
    MDBRow, 
    MDBCol, 
  } from 'mdb-react-ui-kit';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import Footer from '../Components/Footer'
const events = [
    { title: 'Biswakarma Puja', start: '2023-09-17' },
    { title: 'Ganesh Chaturthi, Ganesh Puja', start: '2023-09-19' },
    { title: 'Vijaya Dashami, Dussehra', start: '2023-10-24' },
    {title: 'Kali Puja, Diwali, Narak Chaturdashi', start:'2023-11-12'},
    {title: 'Nagula Chavithi', start: '2023-11-17'}
  ]

export default function UpcomingEvents() {
  return (
    <div>
        <div className='mt-5 p-5 text-center hero-bg text-white'>
          <h2 className='pt-5 mb-3'>Upcoming Events</h2>
          <h6 className='mb-2'>Home / Events</h6>
      </div>
     <MDBContainer>
        <MDBRow className='pt-5 mt-5 mb-5'>
            <MDBCol>
            <FullCalendar
            plugins={[ dayGridPlugin ]}
            initialView="dayGridMonth"
            weekends={true}
            events={events}
            />
            </MDBCol>
        </MDBRow>
     </MDBContainer>
     <Footer />
    </div>
  )
}


