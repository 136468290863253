import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBFooter bgColor='text-white' className='fbg text-center text-lg-start text-muted'>
      <section className='d-flex text-white justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='#' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='#' className='me-4 text-reset'>
            <MDBIcon fab icon="youtube" />
          </a>
          <a href='#' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
        </div>
      </section>

      <section className='text-white'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                Naga Sai Kshetra
              </h6>
              <p>
              Naga Sai Kshetra is the Mahakhandam built by Sadhguru Sai Baba himself as the builder of the ancient Kshetra. Karta, Karma, Kriya All Sri Sainath maharaj for this holy work.
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>About Us</h6>
              <p>
                <a href='/Temple-history' className='text-reset'>
                  Temple History
                </a>
              </p>
              <p>
                <a href='#' className='text-reset'>
                 Sansthan Regulations
                </a>
              </p>
              <p>
                <a href='/Upcoming-events' className='text-reset'>
                 Upcoming Events
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Pooja's & Sevas</h6>
              <p>
                <a href='/Daily-programs' className='text-reset'>
                 Daily Programs
                </a>
              </p>
              <p>
                <a href='/Poojas' className='text-reset'>
                Poojas
                </a>
              </p>
              <p>
                <a href='/Guru-pournami' className='text-reset'>
                  Temple Gallery
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>How to Reach</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Injapur Katta, Injapur (Village), Kammaguda, 
Hayathnagar (M), Ranga Reddy (Dist), T.S. - 501 510.
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                info@nagasaikshethram.org
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 9550 634 449
              </p>  
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4 text-white' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2023 Copyright:
        <a className='text-reset fw-bold' href='#'>
          www.nagasaikshethram.org
        </a>
      </div>
    </MDBFooter>
  );
}