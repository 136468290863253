import React from 'react'
import { 
  MDBContainer, 
  MDBRow, 
  MDBCol, 
  MDBListGroup,
  MDBListGroupItem,
} from 'mdb-react-ui-kit';
import bannner from '../Components/assets/img/poojas.png';
import Footer from '../Components/Footer'

export default function Poojas() {
  return (
    <div className='wrap-bg'>
      <div className='mt-5 p-5 text-center hero-bg text-white'>
        <h2 className='pt-5 mb-3'>Daily Pooja's</h2>
        <h6 className='mb-2'>Home / Poojas</h6>
      </div>

      <MDBContainer className='pt-5 mb-5'>
               <MDBRow>
                <MDBCol>
                  <img src={bannner} className="img-fluid rounded img-thumbnail" alt="" />
                </MDBCol>
               </MDBRow>
              <MDBRow className='mt-5'>
                  <MDBCol size='md pt5'>
                      <div className='ps-3 pe-3 text-left'>
                          <h2>Nitya Poojas performed in <span className='clr-orrange'>Sri Nagasai Temple</span> </h2>
                          <MDBListGroup className='mt-3 mb-3' style={{ width: "50rem", }}>
                          <MDBListGroupItem className='border-0' href="#" >
                            <div className="d-flex w-100 justify-content-between">
                              <h5 className="mb-1">Naaga Archana</h5>
                            </div>
                            <p className="mb-1">Every Tuesday, in the temple, the idol of Naga is adorned with turmeric and kumkum, and offer sesame and jaggery as a sacred offering.</p>
                          </MDBListGroupItem>
                          <MDBListGroupItem className='border-0' href="#" >
                            <div className="d-flex w-100 justify-content-between">
                              <h5 className="mb-1">Vratas and Seva</h5>
                            </div>
                            <p className="mb-1">Every Thursday, the Satya Sai and Satyanarayana Vratas are conducted at the temple, and in the evening, devotees participate in Baba Pallaki Seva.</p>
                          </MDBListGroupItem>
                          
                          </MDBListGroup>
                          <h4 className='clr-orrange'>Festival Events:</h4>
                          <MDBListGroup className='mt-3' style={{ width: "50rem", }}>
                          <MDBListGroupItem className='border-0' href="#" >
                            <div className="d-flex w-100 justify-content-between">
                              <h5 className="mb-1">Special Rituals</h5>
                            </div>
                            <p className="mb-1">During the festivals of <b>Guru Purnima, Nagula Chavithi, Nag Panchami, and Subramanya Shashti,</b> special rituals, prayers, and celebrations are conducted at the temple.</p>
                          </MDBListGroupItem>
                          <MDBListGroupItem className='border-0' href="#" >
                            <div className="d-flex w-100 justify-content-between">
                              <h5 className="mb-1">Chandhanostav Event</h5>
                            </div>
                            <p className="mb-1">On the day of Sri Rama Navami, Chandhanostav is celebrated in honor of Sri Sai Baba.</p>
                          </MDBListGroupItem>
                          <MDBListGroupItem className='border-0' href="#" >
                            <div className="d-flex w-100 justify-content-between">
                              <h5 className="mb-1">Celebrations</h5>
                            </div>
                            <p className="mb-1">The temple conducts grand celebrations for festivals like Navaratri, Diwali, and Ganesh Chaturthi, marking the beginning of these auspicious occasions with great splendor.</p>
                          </MDBListGroupItem>
                          </MDBListGroup>
                          
                
                      </div>
                  </MDBCol>
              </MDBRow>
      </MDBContainer>

      <Footer />
    </div>
  )
}
