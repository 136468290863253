import React from 'react'
import { 
  MDBContainer, 
  MDBRow, 
  MDBCol
} from 'mdb-react-ui-kit';
import Footer from '../Components/Footer'

export default function Temple_history() {
  return (
    <div>
    <div className='mt-5 p-5 text-center hero-bg text-white'>
        <h2 className='pt-5 mb-3'>Temple History</h2>
        <h6 className='mb-2'>Home / Temple History</h6>
    </div>
    <MDBContainer className='pt-5'>
      <MDBRow>
        <MDBCol size='md pt5'>
          <div className='ps-3 pe-3'>
            <h3><span className='clr-orrange'>Naga Sai Kshetra</span> is the Mahakhandam built by Sadhguru Sai Baba himself as the builder of the ancient Kshetra. Karta, Karma, Kriya All Sri Sainath maharaj for this holy work.</h3>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>

    <MDBContainer className='pt-2'>
    <MDBRow>
      
      <MDBCol className='p-5'> 
      <div className='pr-5 pl-5 pt-5 pb-5 p-5 text-dark paper-bg '>
       <div className='ps-5 pe-5 pb-5'>
        <h3 className='pt-5 abt-txt'>* Nagasai Kshetra International Dharma Sansthan *</h3>
        <p className=' mt-4 fs-20'>In the realm of infinite philosophy, where love, kindness, and compassion converge, the avatar Saibaba emerges as a beacon of divine grace. Fifteen years ago, Sai Baba embarked on the remarkable endeavor of single-handedly reconstructing an ancient field, now known as <b>Sri Naga Sai Kshetra,</b> transforming it into a sacred ground that bears the indelible mark of his divine touch. This extraordinary endeavor transcends human capability,  Shri Sai Baba himself led the construction, leaving behind a legacy of spiritual significance.</p>
        <p className='mt-2 fs-20'>The field was purified with all the holy river waters. Sai Baba chose a devoted believer <b>(Sri.H.Swarajya lakshmi Amma Gaaru)</b> to serve as a dedicated servant for this monumental task, exemplifying his faith in the unwavering devotion of his followers.</p>
        <p className='mt-2 fs-20'>Following Baba's instructions, she carried a jole for alms, collecting only 2 rupees from each person, and the first two rupees were offered by Baba himself. With these alms from all states, the construction of the temple began.</p>
        <p className='mt-2 fs-20'>During the construction of the temple a house-like structure 10 feet deep was broken and Naga deities came out from it. She worshiped them every day. Thus wherever they dug in the construction area of ​​the temple they kept coming but did not cause any harm to the people working there. Therefore this temple receives worship as Nagasai Kshetra.</p>
        <p className='mt-2 pb-5 mb-5 fs-20'>During the construction of the temple The sages and Yogis used to come and guide her. Thus Shri Swarajya Lakshmi Amma Garu used to perform yajna sacrifices there with the remembrance of Sai Nama. From the Yajna flames emerged the resplendent Shivalinga and from the Shivalinga emerged the idols of Sri Mahadevi. And idols of Sri Mahalakshmi, Sri Ananta Padmanabha Swamy and Sri Subrahmanyeshwara Swamy also emerged. Even today these deity idols grace the devotees of Sri Sai by receiving abhishekam and pooja with countless devotees in this temple.</p>
      </div>
      </div>
      </MDBCol>
      
    </MDBRow>

    <MDBRow className='pt-3 mb-5'>
        <MDBCol size='md' className='pe-0'>
          <div className='abt-info bg-grdnt rounded-start p-5 text-white'>
            <p className='font-18'>Devotees have strong faith that performing 11 circumambulations around the temple will grant their wishes, and encircling the Guru and Naga deities will also bring about the fulfillment of their desires. Every morning at 3:45, the doors of this sacred place are opened, and the deities are the first to receive worship. Devotees hold the belief that visiting this place, whether it's Yogabhoomi or Siddhabhoomi, brings great blessings to one's intellect.</p>
            <p className='font-18'>With the divine grace of Sri Sainadhu, there is a future plan to construct a free hospital, Vedic Vidyalaya, Goshala, and Nitya Annadana Satram in the area in front of this temple.</p>
            
          </div>
        </MDBCol>
      </MDBRow>

    </MDBContainer>

    <Footer />
      </div>
  );
}


